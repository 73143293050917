import React from 'react';

function Home() {
  return (
    <div>
      <h1>Bem-vindo!</h1>
      <p>Você está logado.</p>
      <a href="http://10.0.2.100:8080/logout">Logout</a>
    </div>
  );
}

export default Home;
