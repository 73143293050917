import React, { useState } from 'react';
import axios from 'axios';
import './Login.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log('Email:', email);
    console.log('Senha:', password);
    try {
      const response = await axios.post('http://localhost:8080/login', { email, password });
      if (response.data) {
        window.location.href = '/home';
      }
    } catch (error) {
      console.error('Erro ao fazer login', error);
    }
  };

  return (
    <div className="main-content">
      <form className="login-form" onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="email">E-Mail:</label>
          <input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div className="form-group">
          <label htmlFor="password">Senha:</label>
          <input type="password" id="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </div>
        <button type="submit">Login</button>
{/*         <div>
          <a href="http://10.0.2.100:8080/oauth2/authorization/google">Login com Google</a>
          <a href="http://10.0.2.100:8080/oauth2/authorization/facebook">Login com Facebook</a>
          <a href="http://10.0.2.100:8080/oauth2/authorization/apple">Login com Apple ID</a>
        </div> */}
      </form>
    </div>
  );
}

export default Login;
