import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {}
      </header>
      <h1 className="App-title">Ctechsis <span className="hebrew">קטכסים</span></h1>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<Login />} />
        </Routes>
      </Router>
      <div className="main-content">
        <p className="description">
          Ctechsis significa Catequese em hebraico. A catequese é um processo essencial na vida cristã, promovendo o crescimento espiritual e a compreensão dos ensinamentos de Jesus Cristo. Ela desempenha um papel vital na formação de uma comunidade de fé forte e comprometida.
        </p>
      </div>
      <footer>
        <a href="https://www.instagram.com/paroquiasaomiguelarcanjomga" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram"></i> paroquiasaomiguelarcanjomga
        </a>
        <a href="https://wa.me/5544999790651" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-whatsapp"></i> Secretaria Paroquial
        </a>
      </footer>
    </div>
  );
}

export default App;
